import { createContext, useContext, useReducer } from "react";

export interface Transaction {
    amount: number;
    occurred_at: string;
    reference: string;
    running_balance_amount: number;
    id: string;
    fee_amount: number;
}

export interface State {
  transactions: Transaction[] | null;
  loading: boolean;
  balance: number | null;
  hasMore: boolean;
}

export interface Action {
  type: string;
  payload: any;
}

const StateContext = createContext<State>({
  transactions: null,
  loading: false,
  balance: null,
  hasMore: false
});
const DispatchContext = createContext<any>(null);

const reducer = (state: State, { type, payload }: Action) => {
  switch (type) {
    case "SET_CONFIG":
      return { ...state, config: payload };
    case "SET_BALANCES":
      return { ...state, transactions: payload };
    case "SET_BALANCE_TOTAL":
      return { ...state, balance: payload };
    case "SET_CURRENT_PAGE":
      return { ...state, currentPage: payload};
    case 'ADD_BALANCES':
        let nl = [...state.transactions ?? [], ...payload]
        const nlMap = new Map(nl.map((m) => [m.id, m])).values()
        return {...state, transactions: Array.from(nlMap)};
    case 'START_LOADING':
        return {...state, loading: true};
    case 'STOP_LOADING':
        return {...state, loading: false};
    case 'HAS_MORE':
        return {...state, hasMore: true};
    case 'NO_MORE':
        return {...state, hasMore: false};
    default:
        throw new Error(`Unknown action type: ${type}`);
  }
};

export const ContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, defaultDispatch] = useReducer(reducer, {
    transactions: null,
    loading: false,
    balance: null,
    hasMore: false
  });
  const dispatch = (type: string, payload?: any) =>
    defaultDispatch({ type, payload });

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};
export const useContextState = () => useContext(StateContext);
export const useContextDispatch = () => useContext(DispatchContext);
