import React, { useEffect } from "react";
import { ContextProvider } from "./components/context/context";
import Routes from "./Routes";
import { GlobalStyle } from "./styles/Global.styles";
import axios from "axios";
import "dotenv/config";

//test

function App() {
  if (process.env.REACT_APP_ENV === "development") {
    axios.defaults.baseURL = "https://ffmrewards21.com/";
  }
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    //@ts-ignore
    favicon.href = process.env.REACT_APP_PAGE_FAVICON ?? "";
    document.title = process.env.REACT_APP_PAGE_TITLE ?? "";
  }, []);

  return (
    <>
      <ContextProvider>
        <GlobalStyle />
        <Routes />
      </ContextProvider>
    </>
  );
}

export default App;
