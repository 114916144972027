import React from "react";
import { Line, SectionContainer } from "../../../shared/Layout.styles";
import { Markdown } from "../../../shared/markdown";
import { successConstants } from "../../../utils/constants/success";

export const Success = () => {
  const { subHeading, successText } = successConstants;
  return (
    <>
      <Line />
      <SectionContainer align="center">
        <Markdown children={subHeading} align="center" />
        <Markdown
          children={successText}
          align="center"
          className="successText"
        />
      </SectionContainer>
    </>
  );
};
