import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Balance, Landing, NotFound, Success, Terms } from "./components/pages";
import { ActivateCard } from "./components/pages";
import { Header } from "./shared/header";
import { Footer } from "./shared/footer";
import ErrorBoundary from "./shared/ErrorBoundary/ErrorBoundary";
import { PageLayoutContainer } from "./shared/Layout.styles";

const Routes = () => {
  return (
    <>
      <HashRouter>
        <Header />
        <PageLayoutContainer>
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/activate" component={ActivateCard} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/balance" component={Balance} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="*" component={NotFound} />
            </Switch>
          </ErrorBoundary>
          <Footer />
        </PageLayoutContainer>
      </HashRouter>
    </>
  );
};
export default Routes;